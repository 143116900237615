import React, {Component} from 'react';
import {BrowserRouter as Router, Link, Route} from "react-router-dom";
import * as qs from 'query-string';

import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';

import './App.css';
import Login from './components/login';
import Profile from './components/profile';
import {StartPage} from './routing';

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

const AppHeader = withStyles({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  login: {
    flexGrow: 1
  }
})(({classes}) => <header className={classes.root}>
  <AppBar position="static" color="default">
    <Toolbar>
      <div className={classes.grow}>
        <Button
            component={Link}
            to={"/"}>
          <Typography className={classes.grow} variant="h5" component="h2"
                      color="inherit">
            Solid Profile Browser
          </Typography>
        </Button>
      </div>
      <Login />
    </Toolbar>
  </AppBar>
</header>);


class App extends Component {
  render() {
    return (
      <div className="App">
        <CssBaseline />
        <Router>
          <div>
            <AppHeader/>
            <main>
              <Route exact path="/" component={StartPage}/>
              <Route path="/profile/" render={({location}) => {
                const params = qs.parse(location.search);
                return <Profile webId={params.webId}/>
              }}/>
            </main>
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
